import React from "react";
import "./Fifth.css";
import arr from "../Assets/arro.png";

function Fifth() {
  return (
    <div className="Fifth">
      <div data-aos="fade-up-right" className="secA">
        <div className="why">
          <span className="head-why">why us</span>
          <br />
          <span className="head-parabold">
            <b>
              Learn Maths online <br /> anywhere !
            </b>
          </span>
          <br />
          <br />
          <span className="head-paralight">
            Access our online platform from the comfort of your home. Study
            mathematics seamlessly, fitting learning into your schedule-no
            matter where you are.
          </span>
        </div>
        <br />

        <div className="secA-items">
          <div className="arrlogo">
            <img src={arr} alt="" width={25} />
          </div>
          <div className="it">
            <span className="it-hed">Customized learning</span>
            <br />
            <br />
            <span className="it-para">
              Tailored lessons cater to individual student needs, ensuring a
              personalized and effective understanding of mathematical concepts.
              Our approach adapts to your child's learning style.
            </span>
          </div>
        </div>
        <br />

        <div className="secA-items">
          <div className="arrlogo">
            <img src={arr} alt="" width={25} />
          </div>
          <div className="it">
            <span className="it-hed">Get expert help when you need it</span>
            <br />
            <br />
            <span className="it-para">
              Our team of experienced instructors provides timely and expert
              help whenever your child encounters challenges. Get the support
              needed to excel in mathematics.
            </span>
          </div>
        </div>
        <br />

        <div className="secA-items">
          <div className="arrlogo">
            <img src={arr} alt="" width={25} />
          </div>
          <div className="it">
            <span className="it-hed">Learn anytime, anywhere</span>
            <br />
            <br />
            <span className="it-para">
              Our platform offers flexibility and accessibility, making math
              learning a convenient and enriching experience for UK
              school students.
            </span>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div data-aos="fade-up-right" className="secB">
        <div className="about-A">
          <span className="Abouthed">About us</span>
          <br />

          <span className="Aboutpara">
            "We prepare you to <br />
            achieve your goals with <br /> professional tutors."
          </span>
          <br />
          <span className="abosamll-para">
            Prepare for triumph with our professional tutors who specialize in
            guiding you toward your goals. Benefit from personalized learning
            experiences tailored to your unique needs, ensuring a deep
            understanding of subjects.
          </span>
          <br />
          <span className="abosamll-para">
            Our tutors, dedicated to your success, bring expertise to every
            session, fostering an environment where academic excellence thrives.
            Let us empower you to conquer challenges, master subjects, and reach
            your aspirations. With our professional tutors by your side, success
            is not just a goal but a journey well- guided and achieved.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Fifth;
