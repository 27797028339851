import React from "react";
import "./Testmo.css";

export const Testmo = () => {
  return (
    <div className="Testmo ">
      <div className="testi-all">
        <div className="testi-hed">
          <p>Testimonials</p>
        </div>

        <div className="testi-grid">
          <div data-aos="zoom-in" className="itemsA">
            <img
              src="https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
            />
            <p>Maggie McLoan</p>
            <span>
              Thanks to the expert tutoring, my daughter Emma not only grasped
              complex math concepts but also developed a love for the subject.
              Her grades soared, and she's now pursuing a STEM
              career with confidence
            </span>
          </div>
          <div data-aos="zoom-in" className="itemsA">
            <img
              src="https://www.bengalurumalayaliforum.com/Contact/ContactCarouselImages/Team/Herold_Mathew-Executive_Member.jpg"
              alt=""
            />
            <p>Mathew Punnakkal</p>
            <span>
              Our tutor's personalized approach turned Alex's struggles in math
              into triumphs. His patience and encouragement transformed Alex's
              mindset, leading to improved grades and a newfound
              enthusiasm for learning.
            </span>
          </div>

          <div data-aos="zoom-in" className="itemsA">
            <img
              src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
            />
            <p>Alexa Horwitz</p>
            <span>
              The enthusiasm our tutor brought to each session made learning
              math enjoyable for my daughter, Ella. The engaging lessons and
              positive reinforcement not only boosted her grades but also fueled
              a genuine interest in mathematics.
            </span>
          </div>

          <div data-aos="zoom-in" className="itemsA">
            <img
              src="https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
            />
            <p>Mark L</p>
            <span>
              James lacked confidence in math, but our tutor's approach changed
              everything. With encouragement and tailored lessons, James not
              only improved his grades but also began to actively participate in
              class, showcasing newfound confidence
            </span>
          </div>

          <div data-aos="zoom-in" className="itemsA">
            <img
              src="https://www.bengalurumalayaliforum.com/Contact/ContactCarouselImages/Team/Sajeev_EJ_Joint_Tresarar.jpg"
              alt=""
            />
            <p> James Antony</p>
            <span>
              Our tutor's effective strategies transformed my son's exam
              preparation. His grades improved significantly, and he now faces
              exams with confidence, thanks to the personalized techniques
              introduced by our dedicated tutor
            </span>
          </div>

          <div data-aos="zoom-in" className="itemsA">
            <img
              src="https://images.pexels.com/photos/262391/pexels-photo-262391.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
            />
            <p>D’Cruz</p>
            <span>
              Thanks to the personalized support and guidance from EduMetrix, my
              son Cameron's academic journey has been nothing short of
              remarkable. His confidence in subjects he once found challenging
              has soared, and he now approaches learning with
              enthusiasm and curiosity."
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
