import React from "react";
import "./Firstpart.css";

export const Firstpart = () => {
  return (
    <div className="Firstpart">
      <span data-aos="zoom-in">
        <b className="bold-a">Free Trial</b>
        <p className="para-b">Feel free to get in touch with us</p>
      </span>
    </div>
  );
};
