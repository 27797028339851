import React from 'react'
import { First } from './FirstSec/First'

import Gridbox from './Gridbox/Gridbox'



import Fouth from './Fourth/Fouth'
import Footer from './Footer/Footer'


function Course() {
  return (
    <div>
      <First/>
      <Gridbox/>
    <Fouth/>
    <Footer/>
     
      
      

    </div>
  )
}

export default Course