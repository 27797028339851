import React from 'react'
import Login from './Loginf/Login'

function Loginall() {
  return (
    <div>
      <Login/>
    </div>
  )
}

export default Loginall
