import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navb from "./Components/Navbar/Navb";

import Home from "./Components/Home";

import Course from "./Components/Navbar-inside/Courses/Course";
import { It } from "./Components/Navbar-inside/IT solution/It";
import { Freetrial } from "./Components/Navbar-inside/FreeTrial/Freetrial";
import Loginall from "./Components/Login/Loginall";
import { Aboutt } from "./Components/Navbar-inside/AboutUS/Aboutt";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navb />}>
          <Route index element={<Home />} />
          <Route path="/course" element={<Course />} />
          <Route path="/IT" element={<It />} />
          <Route path="/about" element={<Aboutt />} />

          <Route path="/login" element={<Loginall />} />

          <Route path="/freetrial" element={<Freetrial />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
