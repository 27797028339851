  import React from "react";
import "./Third.css";
import center from "../Assets/aaa.jpg";

function Third() {
  return (
    <div className="Third">
      <div data-aos="zoom-in-right" className="third-sectionA">
        <span className="contenta">
          How <br /> EduMetrix <br /> Works
        </span>

        <span className="contentb">
          Elevate your child's education effortlessly! Enroll in our courses,
          access a user-friendly platform with engaging resources, and attend
          scheduled live classes with expert instructors. Track progress,
          receive feedback, and witness academic success with personalized
          guidance. A seamless, effective, and enriching learning journey awaits
          your child!.
        </span>
      </div>

      <div  data-aos="zoom-in"  className="third-sectionB">
        <img className="img2" src={center} alt="" />

        <div className="numbermain">
          <div className="numa">1</div>
          <div className="numb">2</div>
          <div className="numc">3</div>
        </div>
      </div>

      <div data-aos="zoom-in-left" className="third-sectionC">
        <div className="Sec-c-A">
          <span className="head">Enroll</span>
          <br />

          <span className="para">
            Choose your course and enroll online effortlessly.
          </span>
        </div>
        <div className="Sec-c-B">
          <span className="head">Schedule Live Classes</span>
          <br />
          <br />
          <span className="para">
            Join scheduled live classes for interactive sessions with expert
            instructors.
          </span>
        </div>
        <div className="Sec-c-C">
          <span className="head">Learn & Progress</span>
          <br />
          <br />
          <span className="para">
            Engage with real-time guidance, comprehensive materials, and
            interactive discussions.Adapt your learning at your own pace. Master
            the subject with personalized guidance and achieve your academic
            goals. It's that simple!
          </span>
        </div>
      </div>
    </div>
  );
}

export default Third;
