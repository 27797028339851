import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import qr from "./Qr.jpg";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="A"></div>
      <div className="B">
        <div className="img-cont">
          <div data-aos="zoom-out" className="foo-img-inside">
            <h6>Choose a teacher for 1-on-1 lessons</h6>

            <div>
              <p>
                {" "}
                <b> Start</b> learning
              </p>
              <p>
                Mathematics <b>today</b>{" "}
              </p>
            </div>

            <button className="btnfooter">
              <Link className="footer-link" to="/freetrial">
                Book free-Trial
              </Link>
            </button>
          </div>
        </div>

        <div className="items">
          <div data-aos="zoom-in-up" className="cont4-footer1">
            <h3>
              <a className="A-tags" href="#">
                About
              </a>
            </h3>
            <p>
              {" "}
              <a className="A-tags" href="">
                How it Works
              </a>
            </p>
            <p>
              {" "}
              <a className="A-tags" href="">
                {" "}
                We are Hiring!
              </a>
            </p>
            <p>
              {" "}
              <a className="A-tags" href="">
                Become a Teacher
              </a>
            </p>
          </div>
          <div data-aos="zoom-in-up" className="cont4-footer1">
            <h3>
              <a className="A-tags" href="">
                Support
              </a>
            </h3>
            <p>
              {" "}
              <a className="A-tags" href="">
                Need any Help?
              </a>
            </p>
            <p>
              <a className="A-tags" href="mailto:contact@edumetrix.uk">
                contact@edumetrix.uk
              </a>
            </p>
          </div>
          <div data-aos="zoom-in-up" className="cont4-footer1">
            <h3>Follow Us</h3>
            <p>
              <i class="fa-brands fa-facebook"></i>{" "}
              <a className="A-tags" href="#">
                {" "}
                Facebook
              </a>
            </p>
            <p>
              <i class="fa-brands fa-instagram"></i>{" "}
              <a className="A-tags" href="#">
                {" "}
                Instagram
              </a>
            </p>
            <p>
              <i class="fa-brands fa-linkedin"></i>{" "}
              <a className="A-tags" href="#">
                Linked In
              </a>
            </p>
          </div>
          <div data-aos="zoom-in-up" className="cont4-footer1">
            <h3>Contact</h3>
            <p className="pp">
              <i id="call" class="bi bi-telephone"></i>

              <a className="A-tags" href="tel:020 8129 4880">
                020 8129 4880
              </a>
            </p>
            <p>
            <a className="A-tags" href="https://wa.me/message/EKV6HBI3EFKBO1?src=qr">
            <img src={qr} alt="" width={80} />
            </a>
            
               {/* <i class="fa-brands fa-whatsapp"></i>*/}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
