import React, { useState } from "react";
import "./IT.css";
import Swal from 'sweetalert2'
// import axios from "axios";


export const It = () => {
  // const [formDataa, setFormDataa] = useState({});

  // const ClickChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormDataa((prev) => ({ ...prev, [name]: value }));
  // };

  // const Submit = async () => {
  //   const requiredFields = ["Name", "Email", "phone", "Message"];

  //   const isEmpty = requiredFields.some((field) => !formDataa[field]);
  //   console.log(formDataa, "dataaaa");

  //   if (isEmpty) {
  //     alert("Please fill in all fields");
  //   } else {
  //     try {
  //       const url = await axios.post(
  //         "http://localhost:3005/send-emaill",
  //         formDataa
  //       );
  //       const response = url.data;
  //       console.log("Response:", response);
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }
  // };




  





  const [formDataaa, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formDataaa,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!formDataaa.name || !formDataaa.email || !formDataaa.phone || !formDataaa.message) {
      // If any field is empty, show an alert
      alert("Please fill in all fields");
      // Swal.fire("Please fill in all fields!");
      return; // Exit the function early
  }

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzxcfm3jyKPk2XPda58Ca8Ih0b_M5mls8_NU4DkQCdcDqtY13X4baqi3sAcrFenaqqd/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formDataaa),
        }
      );

      if (response.ok) {

        Swal.fire({
          title: "Submited",
          text: "Done",
          icon: "success"
        });


        setTimeout(function() {
          window.location.reload();
        }, 3000);

      } else {
        throw new Error("Something went wrong");
      }

      
    } catch (error) {
      alert("Something went wrong");
      console.error(error);
    }
  };

  return (
    // <>
    <div className="App">
      <div>
        {/* ======= Hero Section ======= */}
        <section id="hero" className="d-flex align-items-center">
          <div
            className="container position-relative"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9 text-center">
                <h1>Re-Building The World from Scratch</h1>
                <h2>We are a Team of Talented Developers</h2>
              </div>
            </div>
            <div className="text-center">
              <a href="#get-started" className="btn-get-started scrollto">
                Lets Build it Together
              </a>
            </div>
            <div className="row icon-boxes">
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
                data-aos-delay={200}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="ri-stack-line" />
                  </div>
                  <h4 className="title">
                    <a href>Interactive Website</a>
                  </h4>
                  <p className="description">
                    Our websites are more than just pages; they're engaging
                    experiences. With animations and user-friendly interfaces,
                    we ensure visitors enjoy exploring your site.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="ri-palette-line" />
                  </div>
                  <h4 className="title">
                    <a href>E-Commerce</a>
                  </h4>
                  <p className="description">
                    Simplify online selling with our platforms. We create
                    easy-to-navigate stores that boost sales and keep
                    transactions secure.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
                data-aos-delay={400}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="ri-command-line" />
                  </div>
                  <h4 className="title">
                    <a href>Custom Web Application</a>
                  </h4>
                  <p className="description">
                    Tailored tools for unique needs. Our applications are built
                    to fit your business perfectly, making tasks easier and more
                    efficient.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
                data-aos-delay={500}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="ri-fingerprint-line" />
                  </div>
                  <h4 className="title">
                    <a href>Mobile App</a>
                  </h4>
                  <p className="description">
                    Take your brand mobile. Our apps are sleek, user-friendly,
                    and keep your audience engaged wherever they go.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        <main id="main">
          {/* ======= About Section ======= */}
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>About Us</h2>
                <p>
                  Crafting Digital Excellence: Our Journey to Transforming
                  Businesses with Innovative Website and Mobile Solutions.{" "}
                </p>
              </div>
              <div className="row content">
                <div className="col-lg-6">
                  <p>
                    At EduMetrix Ltd, we pride ourselves on being pioneers in
                    the realm of technology-driven transformations for
                    businesses across the UK and European regions.Our journey
                    has been fueled by an unwavering commitment to revolutionize
                    digital landscapes through bespoke website and mobile
                    application development. With a dedicated team of passionate
                    experts, we embark on each project with a singular mission:
                    to empower businesses with innovative digital solutions that
                    not only drive growth but also redefine success in the
                    digital age.
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line" /> Customized Approach
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> User Experience
                      Focus
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> Commitment to
                      Excellence
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> Interactive Website
                      Development
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> E-Commerce
                      Solutions
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> Mobile App
                      Development
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0">
                  <p>
                    We believe in the power of tailored solutions, understanding
                    that every business is unique. By closely aligning our
                    strategies with the specific needs and objectives of our
                    clients, we ensure that each project is not just a solution
                    but an ideal fit.
                  </p>
                  <p>
                    From the first click to the final interaction, user
                    experience reigns supreme in all our endeavors. Our
                    relentless pursuit of intuitive design and seamless
                    functionality ensures that every website and mobile app we
                    craft not only engages but captivates users, fostering
                    long-lasting relationships and brand loyalty.
                  </p>
                  <p>
                    Excellence isn't just a goal; it's our standard. From the
                    initial consultation to the final delivery and beyond, we
                    hold ourselves to the highest standards of quality and
                    professionalism. Our commitment to excellence permeates
                    every aspect of our work, ensuring unparalleled satisfaction
                    for our clients.
                  </p>
                  <a href="/" className="btn-learn-more">
                    Learn More{" "}
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* End About Section */}

          {/* ======= About Video Section ======= */}
          <section id="about-video" className="about-video">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-lg-6 video-box align-self-baseline position-relative"
                  data-aos="fade-right"
                  data-aos-delay={100}
                >
                  <img
                    src="assets/img/about-video.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-6 pt-3 pt-lg-0 content"
                  data-aos="fade-left"
                  data-aos-delay={100}
                >
                  <h3>Why Choose Our Company</h3>
                  <p className="fst-italic">
                    Choosing EduMetrix Ltd means partnering with a team
                    committed to your digital success. We differentiate
                    ourselves through tailored solutions that align precisely
                    with your business objectives.
                  </p>
                  <ul>
                    <li>
                      <i className="bx bx-check-double" /> We understand that
                      every business is unique. That's why we offer customized
                      digital solutions designed to meet your specific needs and
                      goals.
                    </li>
                    <li>
                      <i className="bx bx-check-double" /> With years of
                      experience in website and mobile app development,
                      e-commerce solutions, and digital marketing, our team
                      brings unparalleled expertise to every project.{" "}
                    </li>
                    <li>
                      <i className="bx bx-check-double" /> We prioritize client
                      satisfaction and go above and beyond to provide
                      exceptional support at every stage of the process, from
                      initial consultation to ongoing maintenance.
                    </li>
                  </ul>
                  <p>
                    With a track record of surpassing expectations, EduMetrix
                    Ltd is primed to empower your business in the digital
                    landscape. Choose us as your trusted partner to elevate your
                    online presence and achieve remarkable success.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* End About Video Section */}

          {/* ======= Clients Section ======= */}
          <section id="clients" className="clients section-bg">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                  data-aos="zoom-in"
                >
                  <img
                    src="assets/img/clients/client-1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                  data-aos="zoom-in"
                >
                  <img
                    src="assets/img/clients/client-2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                  data-aos="zoom-in"
                >
                  <img
                    src="assets/img/clients/client-3.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                  data-aos="zoom-in"
                >
                  <img
                    src="assets/img/clients/client-4.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                  data-aos="zoom-in"
                >
                  <img
                    src="assets/img/clients/client-5.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                  data-aos="zoom-in"
                >
                  <img
                    src="assets/img/clients/client-6.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
          {/* End Clients Section */}

          {/* ======= Services Section ======= */}
          <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Services</h2>
                <p>
                  At EduMetrix Ltd, we specialize in providing comprehensive
                  digital solutions tailored to meet your specific needs.
                </p>
              </div>
              <div className="row">
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="icon-box iconbox-blue">
                    <div className="icon">
                      <svg
                        width={100}
                        height={100}
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          strokeWidth={0}
                          fill="#f5f5f5"
                          d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                        />
                      </svg>
                      <i className="bx bxl-dribbble" />
                    </div>
                    <h4>
                      <a href>Interactive Websites</a>
                    </h4>
                    <p>
                      Transform your online presence with engaging and dynamic
                      websites that captivate visitors and enhance user
                      experience. Our interactive designs ensure your website
                      stands out and leaves a lasting impression.{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  <div className="icon-box iconbox-orange ">
                    <div className="icon">
                      <svg
                        width={100}
                        height={100}
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          strokeWidth={0}
                          fill="#f5f5f5"
                          d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                        />
                      </svg>
                      <i className="bx bx-file" />
                    </div>
                    <h4>
                      <a href>E-Commerce Website</a>
                    </h4>
                    <p>
                      Expand your business reach and boost sales with our
                      custom-built e-commerce websites. From intuitive product
                      browsing to secure payment gateways, we create seamless
                      online shopping experiences for your customers.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                  data-aos="zoom-in"
                  data-aos-delay={300}
                >
                  <div className="icon-box iconbox-pink">
                    <div className="icon">
                      <svg
                        width={100}
                        height={100}
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          strokeWidth={0}
                          fill="#f5f5f5"
                          d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
                        />
                      </svg>
                      <i className="bx bx-tachometer" />
                    </div>
                    <h4>
                      <a href>Custom WebApps</a>
                    </h4>
                    <p>
                      Streamline your business operations and improve efficiency
                      with our tailored web applications. From CRM systems to
                      project management tools, we develop scalable solutions to
                      meet your unique business needs.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="icon-box iconbox-yellow">
                    <div className="icon">
                      <svg
                        width={100}
                        height={100}
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          strokeWidth={0}
                          fill="#f5f5f5"
                          d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"
                        />
                      </svg>
                      <i className="bx bx-layer" />
                    </div>
                    <h4>
                      <a href>Mobile Apps</a>
                    </h4>
                    <p>
                      Reach your customers wherever they are with our
                      cutting-edge mobile apps. Whether iOS or Android, we
                      design and develop user-friendly applications that enhance
                      engagement and drive business growth.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  <div className="icon-box iconbox-red">
                    <div className="icon">
                      <svg
                        width={100}
                        height={100}
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          strokeWidth={0}
                          fill="#f5f5f5"
                          d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"
                        />
                      </svg>
                      <i className="bx bx-slideshow" />
                    </div>
                    <h4>
                      <a href>Project Incubations</a>
                    </h4>
                    <p>
                      Turn your ideas into reality with our project incubation
                      services. From concept validation to prototype
                      development, we provide guidance and support to help you
                      transform your innovative ideas into successful products.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                  data-aos="zoom-in"
                  data-aos-delay={300}
                >
                  <div className="icon-box iconbox-teal">
                    <div className="icon">
                      <svg
                        width={100}
                        height={100}
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke="none"
                          strokeWidth={0}
                          fill="#f5f5f5"
                          d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                        />
                      </svg>
                      <i className="bx bx-arch" />
                    </div>
                    <h4>
                      <a href>Idea to Product </a>
                    </h4>
                    <p>
                      From ideation to execution, we help bring your ideas to
                      life. Our comprehensive approach ensures that your vision
                      is transformed into a tangible product, ready to meet the
                      needs of your target audience.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Sevices Section */}
          {/* ======= Pricing Section ======= */}
          <section id="pricing" className="pricing">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Pricing</h2>
                <p>
                  "Unlock the perfect pricing plan for your business growth with
                  EduMetrix Ltd. Tailored packages for interactive websites,
                  e-commerce platforms, web and mobile apps, project incubation,
                  and idea-to-product services."{" "}
                </p>
              </div>
              <div className="row">
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="zoom-im"
                  data-aos-delay={100}
                >
                  <div className="box">
                    <h3>Interactive Website</h3>
                    <h4>
                      <sup>£</sup>89.99<span> / Month* </span>
                    </h4>
                    <ul>
                      <li>Domain Registration (1st Year)</li>
                      <li>Hosting Services (1 Year)</li>
                      <li>Up to 10 Page Responsive Website</li>
                      <li>Website Content Creation</li>
                      <li>Social Media Integrations</li>
                      <li>Google Listing &amp; Basic SEO </li>
                      <li>1 Year Maintenance Plan</li>
                      {/* <li class="na">Pharetra massa</li>
                <li class="na">Massa ultricies mi</li> */}
                    </ul>
                    <div className="btn-wrap">
                      <a href="#get-started" className="btn-buy">
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="box featured">
                    <h3>E-Commerce Website</h3>
                    <h4>
                      <sup>£</sup>299.99<span> / Month*</span>
                    </h4>
                    <ul>
                      <li>Domain Registration (1st Year)</li>
                      <li>Hosting Services (1 Year)</li>
                      <li>Admin Dashbord &amp; CMS </li>
                      <li>Client Website </li>
                      <li>Easy Product Adding &amp; Tracking</li>
                      <li>Google Listing &amp; SEO </li>
                      <li>1 Year Maintenance Plan</li>
                    </ul>
                    <div className="btn-wrap">
                      <a href="#get-started" className="btn-buy">
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 mt-4 mt-lg-0"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="box">
                    <h3>Custom Web &amp; Mobile App</h3>
                    <h4>
                      <sup>£</sup>999.99<span> / Month*</span>
                    </h4>
                    <ul>
                      <li>Android App</li>
                      <li>IOS App</li>
                      <li>Admin Dashbord &amp; CMS </li>
                      <li>Client Website </li>
                      <li>Native Application</li>
                      <li>Google Listing &amp; SEO </li>
                      <li>1 Year Maintenance Plan</li>
                    </ul>
                    <div className="btn-wrap">
                      <a href="#get-started" className="btn-buy">
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Pricing Section */}

          {/* 
    {/* ======= FAQ ======= */}

          {/* 
    {/* ======= FAQ ======= */}

          {/* 
    {/* ======= Contact Section ======= */}
          <section id="get-started" class="get-started section-bg">
            <div class="container">
              <div class="row justify-content-between gy-4">
                <div
                  class="col-lg-6 d-flex align-items-center"
                  data-aos="fade-up"
                >
                  <div class="content">
                    <h3>"Let's Customize Your Pricing over a Coffee"</h3>
                    <p>
                      We understand that every business is unique, with distinct
                      needs and objectives. That's why we offer flexible pricing
                      plans and customization options to ensure that you get the
                      perfect solution tailored to your business growth.
                    </p>
                    <p>Whether you're looking to:</p>
                    <ul>
                      <li>
                        <i class="bi bi-check-circle"></i>{" "}
                        <span>
                          enhance your online presence with an interactive
                          website.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check-circle"></i>{" "}
                        <span>boost sales with an e-commerce platform.</span>
                      </li>
                      <li>
                        <i class="bi bi-check-circle"></i>{" "}
                        <span>
                          streamline your operations with custom web and mobile
                          apps
                        </span>
                      </li>
                    </ul>
                    <p>We have the perfect package for you.</p>
                  </div>
                </div>

                <div class="col-lg-5" data-aos="fade">
                  <form className="php-email-form">
                    <h3>Get a Customized Quote</h3>
                    <p>
                      Ready to unlock the perfect pricing plan for your business
                      growth? Contact us now to discuss your needs and explore
                      customization options tailored to your unique
                      requirements.
                    </p>
                    <div class="row gy-3">
                      {/* <div class="col-md-12">
                        <input
                          value={formDataa.Name}
                          onChange={ClickChange}
                          id="Name"
                          type="text"
                          name="Name"
                          class="form-control"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div class="col-md-12">
                        <input
                          value={formDataa.Email}
                          onChange={ClickChange}
                          id="Email"
                          type="email"
                          class="form-control"
                          name="Email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div class="col-md-12">
                        <input
                          value={formDataa.Phone}
                          onChange={ClickChange}
                          id="Phone"
                          type="text"
                          class="form-control"
                          name="phone"
                          placeholder="Phone"
                          required
                        />
                      </div>
                      <div class="col-md-12">
                        <textarea
                          value={formDataa.Message}
                          onChange={ClickChange}
                          id="Message"
                          class="form-control"
                          name="Message"
                          rows="6"
                          placeholder="Message"
                          required=""
                        ></textarea>
                      </div>
                      <div class="col-md-12 text-center">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">
                          Your quote request has been sent successfully. Thank
                          you!
                        </div>
                        <button
                          id="get-quote-btn"
                          type="submit"
                          onClick={Submit}
                        >
                          Submit
                        </button>
                      </div> */}

                    
                      {/* <label For="fname" className="col-md-12">Name</label> */}
                      <input className="inp"
                        type="text"
                        id="name"
                        name="name"
                        value={formDataaa.name}
                        onChange={handleChange}
                        placeholder="Name"
                        required
                      />

                      {/* <label For="lname">email</label> */}
                      <input className="inp"
                        type="text"
                        id="email"
                        name="email"
                        value={formDataaa.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                      />
                      {/* <label For="lname">phone</label> */}
                      <input className="inp"
                        type="text"
                        id="email"
                        name="phone"
                        value={formDataaa.phone}
                        onChange={handleChange}
                        placeholder="Phone"
                        required
                      />

                      {/* <label For="lname">subject</label> */}
                      <textarea
                        name="message"
                        value={formDataaa.message}
                        onChange={handleChange}
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Message"
                        required
                      ></textarea>

                      <button
                        id="get-quote-btn"
                        type="submit"
                        onClick={handleSubmit}
                       
                      >
                        submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        </main>
      </div>
    </div>
    // </>
  );
};
