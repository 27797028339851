import React from 'react'
import Gridbox from './Gridbox/Gridbox'
import Third from './Thirdsec/Third'
import Fouth from './Fourth/Fouth'
import Fifth from './Fifth/Fifth'
import Footer from './Footer/Footer'
import Header from './Header/Header'

const Home = () => {
  return (
    <div>
      <Header/>
      <Gridbox />
      <Third />
      <Fouth />
      <Fifth />
      <Footer />
    </div>
  )
}

export default Home
