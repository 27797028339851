import React from "react";
import "./Navb.css";

import logo from "./logo new.png";
import { Link, Outlet } from "react-router-dom";

function Navb () {
  return (
    <>
      <div id="project">
        <div className="nav">
          <div className="nav-main">
            <div className="nav-img">
              <img src={logo} alt="" />
            </div>
            <div className="nav-content1">
              <Link className="link" to="/">
                <p>Home</p>
              </Link>

              <Link className="link" to="/course">
                <p>Courses</p>
              </Link>

              <Link className="link" to="/about">
                <p>About Us</p>
              </Link>

              <Link className="link" to="/IT">
                <p>IT solution</p>
              </Link>

              <Link className="link" to="/freetrial">
                <p>Free trial</p>
              </Link>

              <div className="nav-tutorA">
                <Link className="linku" to="/login">
                  <p>Login</p>
                </Link>
              </div>
            </div>

            <div className="offcanvs">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/128/9304/9304700.png"
                    alt=""
                    width="30"
                  />
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <Link className="drop-link" to="/">
                      <button class="dropdown-item" type="button">
                        Home
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link className="drop-link" to="/course">
                      <button class="dropdown-item" type="button">
                        Courses
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <button class="dropdown-item" type="button">
                        About Us
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/IT">
                      <button class="dropdown-item" type="button">
                        IT Solution
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/freetrial">
                      <button class="dropdown-item" type="button">
                        Freetrial
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login">
                      <button ClickLogin class="dropdown-item" type="button">
                        Log in
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Navb;
