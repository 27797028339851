import React from "react";
import "./Fourth.css";

function Fouth() {
  return (
    <div className="Fouth">
      <div className="cont">
        <div data-aos="zoom-in" className="cont3-1">
          <div className="content-img">
            <i class="fa-solid fa-graduation-cap"></i>
          </div>
          <h5>Expert Tutors</h5>
          <p>
            Master math with our expert instructors. Passionate,experienced,and
            dedicated to your success. Unleash your mathematical potential with
            us.
          </p>
        </div>
        <div data-aos="zoom-in" className="cont3-1">
          <div className="content-img">
            <i class="fa-solid fa-user"></i>
          </div>
          <h5>Verified Profiles</h5>
          <p>
            Trust excellence. Our instructors boast verified credentials,
            ensuring a reliable and professional learning experience. Elevate
            your education with confidence.
          </p>
        </div>
        <div data-aos="zoom-in" className="cont3-1">
          <div className="content-img">
            <i class="fa-solid fa-book-open-reader"></i>
          </div>
          <h5>Monthly Subsciption</h5>
          <p>
            Unlock continuous learning with our flexible monthly subscription .
            Access premium content, stay engaged, and make education a seamless
            part of your routine. Subscribe now!
          </p>
        </div>
        <div data-aos="zoom-in" className="cont3-1">
          <div className="content-img">
            <i class="fa-solid fa-dollar-sign"></i>
          </div>
          <h5>Affordable Prices</h5>
          <p>
            Quality education shouldn't break the bank. Enjoy our expert
            teaching at budget-friendly rates. Elevate your learning without
            compromising your budget. Enroll tooday!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Fouth;
