import React, { useState } from "react";
import "./Login.css";
// import logo from '../Components/img/logo.png';
// import { LiaUserTieSolid } from "react-icons/lia";

import { PiStudentLight } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { CiUser } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [selectedUserType, setSelectedUserType] = useState("");
  const [forgot, setForgot] = useState(false);
  const [Input, setInput] = useState("");
  const [Error, setError] = useState("");

  const handleChange = (e) => {
    setInput(e.target.value);
  };
  console.log("input", Input);

  const handleEmail = () => {
    if (Input === "") {
      setError("please enter registed email");
    } else {
      alert("link sended your email");
    }
  };

  const open = () => {
    setForgot(!forgot);
  };

  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLogin = () => {
    console.log("Logging in as:", selectedUserType);

    if (selectedUserType === "admin") {
      navigate("/admin");
    } else if (selectedUserType === "teacher") {
      navigate("/teacher");
    } else if (selectedUserType === "student") {
      navigate("/student");
    }
  };

  return (
    <>
      {/* <Navb onClickLogin={ClickLogin} /> */}

      <div className="login-page-main-div">
        <div className="login-page-main">
          <div className="login-page-1">
            {/* <div className="login-page-1-logo">
              <img src={logo} alt="" />
            </div> */}
          </div>

          <div className="login-page-2">
            <div className="login-page-2-ptext">
              <p> I am </p>
            </div>

            <div className="login-page-2-type">
              {/* <div
                className={`login-page-2-type-admin ${
                  selectedUserType === "admin" ? "selected" : ""
                }`}
                onClick={() => setSelectedUserType("admin")}
              >
                <LiaUserTieSolid
                    className={`login-page-2-type-admin-img  ${
                      selectedUserType === "admin" ? "colurchange" : ""
                    }`}
                  />
                  Admin
              </div> */}

              <div
                className={`login-page-2-type-admin ${
                  selectedUserType === "teacher" ? "selected" : ""
                }`}
                onClick={() => setSelectedUserType("teacher")}
              >
                <GiTeacher
                  className={`login-page-2-type-admin-img  ${
                    selectedUserType === "teacher" ? "colurchange" : ""
                  }`}
                />
                Teacher
              </div>

              <div
                className={`login-page-2-type-admin ${
                  selectedUserType === "student" ? "selected" : ""
                }`}
                onClick={() => setSelectedUserType("student")}
              >
                <PiStudentLight
                  className={`login-page-2-type-admin-img  ${
                    selectedUserType === "student" ? "colurchange" : ""
                  }`}
                />
                Student
              </div>
            </div>
          </div>

          <div className="login-page-3">
            <div className="login-page-3-input">
              <div className="login-page-3-input-icon">
                <CiUser className="login-page-3-input-icon-icon" />
              </div>
              <input
                type="text"
                className="login-page-3-input-input"
                placeholder="User Name"
                name="username"
              />
            </div>

            <div className="login-page-3-input">
              <div className="login-page-3-input-icon">
                <CiLock className="login-page-3-input-icon-icon" />
              </div>
              <input
                type="password"
                className="login-page-3-input-input"
                placeholder="Password"
              />
            </div>

            <div className="login-page-3-input-2">
              <div className="login-page-3-input-2-1">
                <input type="checkbox" />
              </div>
              <div className="login-page-3-input-2-div">
                <p>Remember Me</p>
              </div>
            </div>
          </div>

          <div className="login-page-4">
            <button onClick={handleLogin}>Log in</button>
          </div>

          <div className="login-page-5" onClick={open}>
            Forgot password
          </div>
        </div>

        {forgot && (
          <div className={`login-page-forgot ${forgot ? "show" : ""}`}>
            <div className="login-page-main-forgot">
              <div className="login-input-forgot-main">
                <input
                  className="login-input-forgot"
                  placeholder="Email address"
                  type="email"
                  name="email"
                  onChange={handleChange}
                />
                <p className="login-page-alert">{Error}</p>
              </div>

              <div className="login-button-forgot-main">
                <button className="login-button-forgot" onClick={handleEmail}>
                  Reset password
                </button>
                <p className="login-p-forgot" onClick={open}>
                  Back to Sign in
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
