import React from "react";
import "./Gridbox.css";

const Gridbox = () => {
  return (
    <div className="gridboxx-A">
      <div data-aos="fade-up" className="boxouter-A">
        <div className="box-A">
          <div>KS 2</div>
          <span className="second">Year 4</span>
          <span className="last">Mathematics</span>
        </div>

        <div  data-aos="fade-up"className="box-A">
          <div>KS 2</div>
          <span className="second">Year 5</span>
          <span className="last">Mathematics</span>
        </div>

        <div  data-aos="fade-up" className="box-A">
          <div>KS 2</div>
          <span className="second">Year 6</span>
          <span className="last">Mathematics</span>
        </div>

        <div  data-aos="fade-up"className="box-A">
          <div>KS 3</div>
          <span className="second">Year 7</span>
          <span className="last">Mathematics</span>
        </div>

        <div  data-aos="fade-up" className="box-A">
          <div>KS 3</div>
          <span className="second">Year 8</span>
          <span className="last">Mathematics</span>
        </div>

        <div  data-aos="fade-up" className="box-A">
          <div>KS 3</div>
          <span className="second">Year 9</span>
          <span className="last">Mathematics</span>
        </div>

        <div  data-aos="fade-up" className="box-A">
          <div>KS 4</div>
          <span className="second">Year 10</span>
          <span className="last">Mathematics</span>
        </div>

        <div  data-aos="fade-up" className="box-A">
          <div>KS 4</div>
          <span className="second">Year 11</span>
          <span className="last">Mathematics</span>
        </div>
      </div>
    </div>
  );
};

export default Gridbox;
