import React from 'react'
import "./Acco.css"

export const Acco = () => {
  return (
    <div className='Acco'>
     <div className='Acco-hed'>
        <span>Frequently Asked Questions</span>
     </div>

     <div className='Acco-silde'></div>
         
     <div class="accordion" id="accordionExample">
          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1.How does online tutoring work on your platform?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Our online tutoring platform provides live, interactive sessions
                between students and qualified tutors. Users can schedule
                sessions, access learning materials, and engage in personalized
                lessons from the comfort of their homes.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                2.What subjects and levels do your tutors cover?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Our tutors cover a wide range of subjects and academic levels,
                from elementary school to advanced courses. We strive to meet
                the diverse learning needs of students across various
                educational stages.
              </div>
            </div>
          </div>
          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                3.Are the tutors qualified and experienced?
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Yes, all our tutors are thoroughly vetted, qualified
                professionals with extensive teaching experience. We ensure that
                they have the expertise needed to provide effective and engaging
                tutoring sessions.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                4.What makes your tutoring approach different from others?
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Our approach is centered on personalized learning. We tailor
                lessons to each student's unique learning style, fostering a
                deeper understanding of subjects and promoting academic success.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
              >
                5.How are lessons personalized to suit individual learning
                needs?
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Our tutors assess each student's strengths and weaknesses,
                creating a customized learning plan. This approach ensures that
                sessions address specific challenges and reinforce areas where
                improvement is needed.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                6.What technology or tools are required for online tutoring
                sessions?
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                A stable internet connection and a device such as a computer or
                tablet are required. Our platform supports video conferencing,
                interactive whiteboards, and other tools to facilitate effective
                online learning.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="true"
                aria-controls="collapseSeven"
              >
                7.Can I schedule tutoring sessions at flexible times?
              </button>
            </h2>
            <div
              id="collapseSeven"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Yes, we offer flexibility in scheduling. You can choose
                convenient times for tutoring sessions, making it easier to
                integrate learning into your daily routine.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                8.Is there a trial period or demo available before committing to
                tutoring services?
              </button>
            </h2>
            <div
              id="collapseEight"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Yes, we offer a trial period or demo session for new users. This
                allows you to experience our tutoring services firsthand before
                making a commitment.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="true"
                aria-controls="collapseNine"
              >
                9.What is the pricing structure for tutoring services? Are there
                any hidden fees?
              </button>
            </h2>
            <div
              id="collapseNine"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Our pricing structure is transparent, and there are no hidden
                fees. You can find detailed information about our pricing plans
                on our website.
              </div>
            </div>
          </div>

          <div data-aos="fade-up" class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="true"
                aria-controls="collapseTen"
              >
                10.Do you offer group tutoring sessions or only one-on-one
                sessions?
              </button>
            </h2>
            <div
              id="collapseTen"
              class="accordion-collapse collapse "
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                We offer both one-on-one and group tutoring sessions, allowing
                students to choose the format that best suits their learning
                preferences and needs.
              </div>
            </div>
          </div>
        </div>


    </div>
  )
}
