import React from 'react'
import "./Firstarea.css"

export const Firstarea = () => {
  return (
    <div className='Firstarea'>

        <span data-aos="zoom-in">
        “ Harmony in Learning:Connecting Bright Minds and Inspiring Educators for Limitless Educational Adventures!"
        </span>


    </div>
  )
}
