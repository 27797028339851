import React, { useEffect } from "react";
import "./Gridbox.css";
import AOS from "aos";
import { Link } from "react-router-dom";

const Gridbox = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });

  return (
    <div className="gridboxx">
      <div className="boxouter">
        <div data-aos="fade-up" className="box-home">
          <div>KS 2</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 4</span>
          </Link>

          <span className="last">Mathematics</span>
        </div>

        <div data-aos="fade-up" className="box-home">
          <div>KS 2</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 5</span>
          </Link>

          <span className="last">Mathematics</span>
        </div>

        <div data-aos="fade-up" className="box-home">
          <div>KS 2</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 6</span>
          </Link>
          <span className="last">Mathematics</span>
        </div>

        <div data-aos="fade-up" className="box-home">
          <div>KS 3</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 7</span>
          </Link>
          <span className="last">Mathematics</span>
        </div>

        <div data-aos="fade-up" className="box-home">
          <div>KS 3</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 8</span>
          </Link>
          <span className="last">Mathematics</span>
        </div>

        <div data-aos="fade-up" className="box-home">
          <div>KS 3</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 9</span>
          </Link>
          <span className="last">Mathematics</span>
        </div>

        <div data-aos="fade-up" className="box-home">
          <div>KS 4</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 10</span>
          </Link>
          <span className="last">Mathematics</span>
        </div>

        <div data-aos="fade-up" className="box-home">
          <div>KS 4</div>
          <Link className="home-link" to="/freetrial">
            <span className="second">Year 11</span>
          </Link>
          <span className="last">Mathematics</span>
        </div>
      </div>
    </div>
  );
};

export default Gridbox;
