import React from 'react'
import { Firstarea } from './FirstArea/Firstarea'
import Sec from './SecArea/Sec'
import { Acco } from './Accordion/Acco'
import { Testmo } from './Testmonial/Testmo'
import Footer from './Footer/Footer'



export const Aboutt = () => {
  return (
    <div>
    <Firstarea/>
    <Sec/>
    <Acco/>
    <Testmo/>
    <Footer/>
    


    </div>
  )
}
