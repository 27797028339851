import React, { useState } from "react";
import "./Form.css";
import Swal from "sweetalert2";
// import axios from "axios";

export const Form = () => {
  // const [formData, setFormData] = useState({});

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  // const Book = async () => {
  //   const requiredFields = [
  //     "parentName",
  //     "phoneNumber",
  //     "email",
  //     "available_Date",
  //     "callavailable_Time",
  //     "address",
  //     "student_name",
  //     "student_class",
  //     "student_DOB",
  //     "subjectsRequired",
  //     "TrialAvailableDate",
  //     "TrialAvailableTime",
  //     "additionalMessage",
  //   ];

  //   const isEmpty = requiredFields.some((field) => !formData[field]);

  //   if (isEmpty) {
  //     alert("Please fill in all fields");
  //   } else {
  //     try {
  //       const url = await axios.post(
  //         "http://localhost:3005/send-email",
  //         formData
  //       );
  //       const response = url.data;
  //       console.log("Response:", response);

  //     } catch (error) {

  //       console.error("Error:", error);
  //     }
  //   }
  // };

  const [formDataB, setFormDataB] = useState({
    parentName: "",
    phoneNumber: "",
    email: "",
    // available_Date: "",
    // callavailable_Time: "",
    // address: "",

    student_name: "",
    // student_DOB: "",
    student_class: "",
    subjectsRequired: "",
    ReferenceID:"",
    // TrialAvailableDate: "",
    // TrialAvailableTime: "",
    additionalMessage: "",
  });

  const handleChanger = (e) => {
    setFormDataB({
      ...formDataB,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitr = async (e) => {
    e.preventDefault();

    if (
      !formDataB.parentName ||
      !formDataB.email ||
      !formDataB.phoneNumber ||
      // !formDataB.available_Date ||
      // !formDataB.callavailable_Time ||
      // !formDataB.address ||

      !formDataB.student_name ||
      !formDataB.student_class ||
      !formDataB.subjectsRequired ||
      // !formDataB.TrialAvailableDate ||
      // !formDataB.TrialAvailableTime ||
      // !formDataB.ReferenceID||
      !formDataB.additionalMessage
    ) {
      // If any field is empty, show an alert
      alert("Please fill in all fields");
      // Swal.fire("Please fill in all fields!");
      return; // Exit the function early
    }
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbw1xL3YVVdlHirAWlfqXDuSS73Mul9WFRZHIlV7AF07ixiFq9x9dJbXrihfXuJnqlsXEQ/exec ",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formDataB),
        }
      );

      if (response.ok) {
        // alert("successfully");
        Swal.fire({
          title:
            "Thank you for submitting the form. One of our agents will contact you as soon as possible",
          text: "Done",
          icon: "success",
        });

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      alert("Something went wrong");
      console.error(error);
    }
  };

  return (
    <div className="freetrial-main">
      <div className="Form-new">
        <div data-aos="zoom-in" className="side-A">
          <p className="form-hedings">Parent Details</p>

          <div className="nameofp">
            <input
              id="parentName"
              name="parentName"
              required
              value={formDataB.parentName}
              onChange={handleChanger}
              placeholder="Name "
              class="input-nameofpa"
              type="text"
            />

            <input
              id="phoneNumber"
              required=""
              type="text"
              name="phoneNumber"
              value={formDataB.phoneNumber}
              onChange={handleChanger}
              placeholder="Phone"
              class="input-nameofpa"
            />
          </div>

          <input
            placeholder="Email"
            class="input-communi"
            name="email"
            type="email"
            id="email"
            value={formDataB.email}
            onChange={handleChanger}
            required=""
          />
          {/* 
          <div className="iam-avilable">
            <p className="inner-buton"> Avilable for Call</p>

            <div className="date-time">
              <input
                id="callAvailableDate"
                required=""
                class="input-date"
                name="available_Date"
                type="date"
                value={formDataB.available_Date}
                onChange={handleChanger}
              />

              <input
                id="callAvailableTime"
                class="input-date"
                required=""
                type="time"
                name="callavailable_Time"
                value={formDataB.callavailable_Time}
                onChange={handleChanger}
              />
            </div>
          </div> */}

          {/* <input
            id="communicationAddress"
            placeholder="Communication Address"
            class="input-communi"
            required=""
            type="text"
            name="address"
            value={formDataB.address}
            onChange={handleChanger}
          /> */}
        </div>
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <div data-aos="zoom-in" className="side-B">
          <br />

          <p className="form-hedings">Student Details</p>

          <div className="nameofpp">
            <input
              id="studentName"
              placeholder="Name"
              class="input-nameofpap"
              required=""
              type="text"
              name="student_name"
              value={formDataB.student_name}
              onChange={handleChanger}
            />

            {/* <input
              id="subjectsRequired"
              placeholder="DOB (d/m/y)"
              class="input-nameofpa"
              required=""
              type="date"
              name="student_DOB"
              value={formDataB.student_DOB}
              onChange={handleChanger}
            /> */}
          </div>

          <div className="select-div">
            <select
              id="studentDOB"
              className="select"
              name="student_class"
              value={formDataB.student_class}
              onChange={handleChanger}
            >
              <option value="">Class</option>
              <option value="Year 4">Year 4</option>
              <option value="Year 5">Year 5</option>
              <option value="Year 6">Year 6</option>
              <option value="Year 7">Year 7</option>
              <option value="Year 8">Year 8</option>
              <option value="Year 9">Year 9</option>
              <option value="Year 10">Year 10</option>
              <option value="Year 11">Year 11</option>
            </select>

            <select
              id="subjectsRequired"
              className="select"
              name="subjectsRequired"
              value={formDataB.subjectsRequired}
              onChange={handleChanger}
            >
              <option value="">Subject</option>
              <option value="Mathematics">Mathematics</option>
              <option value="Science">Science</option>
              <option value="All">All</option>
            </select>
          </div>

          <div className="iam-avilable">
          
            {/* <p className="inner-butonn">Trial Session</p> */}

            <div className="date-time">
              {/* <input
                id="callAvailableDate"
                class="input-date"
                required=""
                type="date"
                name="TrialAvailableDate"
                value={formDataB.TrialAvailableDate}
                onChange={handleChanger}
              /> */}

              <input
                id="callAvailableTime"
                class="input-date"
                placeholder="Reference ID (optional)"
                required=""
                type="text"
                name="ReferenceID"
                value={formDataB.ReferenceID}
                onChange={handleChanger}
              />
            </div>
          </div>
        </div>
      </div>

      {/* *************** TEXTAREA***********************************************88 */}

      <div className="Textarea">
        <textarea
          
          className="textcardz"
          placeholder="  Additional Message"
          name="additionalMessage"
          id="additionalMessage"
          cols="30"
          rows="10"
          value={formDataB.additionalMessage}
          onChange={handleChanger}
        ></textarea>

        <button type="submit" onClick={handleSubmitr}>
          Book Appointment
        </button>
      </div>
    </div>
  );
};
