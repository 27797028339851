import React from "react";
import "./Sec.css";

function Sec() {
  return (
    <div className="Sec">
      <div data-aos="fade-up" className="A-sec">
        <p>About US</p>
        <span>
          EduMetrix, we are driven by a visionary commitment to redefine
          education. Our platform stands as a beacon for students seeking
          academic excellence, providing a transformative learning experience.
          With a carefully curated team of expert tutors, we aim to instill a
          love for learning while ensuring a strong foundation in key subjects.
          We believe that education should not only be accessible but also
          inspiring, fostering a lifelong passion for knowledge.
        </span>
      </div>
      <div className="B-sec">
        <div data-aos="fade-right" className="ourmission">
          
          <div className="img-our">
            <img
              src="https://images.pexels.com/photos/5088181/pexels-photo-5088181.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt=""
            />
          </div>
          <div className="our-text">
              <p>Our Mission</p>
              <span>Empower students for success with accessible, personalized education. Foster a love for learning and equip learners with skills for a changing world, guided by dedicated tutors committed to academic excellence and personal growth.</span>

          </div>
        </div>
        <div data-aos="fade-left" className="ourmission">
        <div className="img-our">
            <img
              src="https://images.pexels.com/photos/5905866/pexels-photo-5905866.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
            />
          </div>
          <div className="our-text">
              <p>Our Vision</p>
              <span>Redesign education's future by inspiring dynamic and accessible learning environments. Envision a world where every student reaches their full potential, driven by passionate tutors cultivating critical thinking, creativity, and a lifelong love for learning.</span>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Sec;
