import React from "react";
import "./First.css";

export const First = () => {
  return (
    <div  className="First">
      <span data-aos="zoom-in">
        <b>Prepare</b> to learn <b>Mathematics</b> with Our best online
        <b>tutors.</b>
      </span>
    </div>
  );
};
