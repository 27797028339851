import React from "react";
import { Firstpart } from "./FirstSec/Firstpart";
import { Form } from "./FORM/Form";






export const Freetrial = () => {
  return (
    <div>
      <Firstpart />
      <Form/>
    
      

      
    </div>
  );
};
