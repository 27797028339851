import React from "react";
import "./Header.css";
import vid from "../Assets/vid.mp4";

function Header() {
  return (
    <div className="Header-main">
        <video autoPlay loop muted src={vid}></video>
      {/* <div className="header"> */}
        <div  data-aos="zoom-in" className="head-in">
          <p>In look for a Mathematics tutor ? </p>
          <br />

          <span>
            Start <b>learning </b> Mathematics
          </span>

          <span>
            today <b>with the best</b>
          </span>

          <span> online tutors </span>

          <br />
          <br />
          <img
            src="https://cdn-icons-png.flaticon.com/128/3298/3298615.png"
            alt=""
            width={40}
          />
        </div>
      {/* </div> */}
    </div>
  );
}

export default Header;
